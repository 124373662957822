interface stateType {
  [key: string]: unknown;
}
interface GlobalType {
  isMobile: boolean;
}

export const globalData: GlobalType = {
  isMobile: false,
};
const devApi = 'https://000803.cn/api/';
// const devApi = 'https://offce.becw.top/api/';
export const apiServer: string = process.env.NODE_ENV === 'production' ? '/api/' : devApi;
