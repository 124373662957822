import { First } from 'react-bootstrap/esm/PageItem';

interface stateType {
  [key: string]: unknown;
}
interface historyType {
  jumpKey: string;
  scrollTop: number;
}
export const routeJump = (history: any, url: string, params: stateType = {}) => {
  if (!params) params = {};
  let oldJumpKey;
  if (history.location.state) {
    oldJumpKey = history.location.state.jumpKey;
    addHistory(oldJumpKey);
  } else {
    oldJumpKey = 'index';
    addHistory(oldJumpKey);
  }

  let newJumpKey = new Date().getTime() + Math.ceil(Math.random() * 100) + '';
  params.jumpKey = newJumpKey;
  history.push(url, params);
  return null;
};
export const addHistory = (jumpKey: string) => {
  let historyInfo: historyType[] = getSession('historyInfo') || [];
  if (historyInfo.length > 100) {
    historyInfo = historyInfo.splice(-99);
  }
  let scrollTop: number = 0;
  if (document.documentElement && document.documentElement.scrollTop) {
    scrollTop = document.documentElement.scrollTop;
  } else if (document.body) {
    scrollTop = document.body.scrollTop;
  }
  let i = 0;
  let len = historyInfo.length;
  let item = null;
  for (i; i < len; i++) {
    if (historyInfo[i].jumpKey === jumpKey) {
      item = historyInfo[i];
      item.scrollTop = scrollTop;
      historyInfo.splice(i, 1);
      historyInfo.push(item);
      break;
    }
  }
  if (item === null) {
    historyInfo.push({ jumpKey: jumpKey, scrollTop: scrollTop });
  }

  addSession('historyInfo', historyInfo);
};
/**
 * 时间戳转换
 * @Author duan     2017-09-15T13:44:23+0800
 * @param  {[type]} shijianchuo              [description]
 * @param  {[type]} type                     [description]
 * @return {[type]}                          [description]
 */
export const format = (shijianchuo: string | number, type: string) => {
  if (!shijianchuo) return '/';
  //shijianchuo是整数，否则要parseInt转换
  var time = new Date(parseInt(shijianchuo + '') * 1000);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  var add0 = function (m: number): string {
    return m < 10 ? '0' + m : m + '';
  };
  switch (type) {
    case 'minDay':
      return y + '/' + add0(m) + '/' + '01';
    default:
      return type
        .replace('yyyy', y + '')
        .replace('MM', add0(m))
        .replace('dd', add0(d))
        .replace('hh', add0(h))
        .replace('mm', add0(mm))
        .replace('ss', add0(s));
  }
};
export const isMobile = (): boolean => {
  const windowWidth =
    document.body.offsetWidth < window.screen.width
      ? document.body.offsetWidth
      : window.screen.width;
  if (windowWidth >= 785) {
    return false;
  } else {
    return true;
  }
};
/**
 * 写入localStorage缓存 时间以秒计算
 * @Author   duan
 * @DateTime 2018-11-20
 * @param    {obj}   obj {info,name,time}
 */
export const addStorage = (name: string, info: stateType | unknown[]) => {
  localStorage[name] = JSON.stringify(info);
};
//取localStorage缓存
export const getStorage = (name: string) => {
  let info = localStorage[name];
  if (typeof info == 'undefined' || info === null || info === 'null') {
    return null;
  }
  return JSON.parse(info);
};
/**
 * 写入session缓存 时间以秒计算
 * @Author   duan
 * @DateTime 2018-11-20
 * @param    {obj}   obj {info,name,time}
 */
export const addSession = (name: string, info: stateType | unknown[]) => {
  sessionStorage[name] = JSON.stringify(info);
};
//取session缓存
export const getSession = (name: string) => {
  let info = sessionStorage[name];
  if (typeof info == 'undefined' || info === null || info == 'null') {
    return null;
  }
  return JSON.parse(info);
};
export const openTabs = (url: string, download = 0, fileName = '', target = '_blank') => {
  if (download === 0) {
    // 如果是图片和pdf download=0  否则download=1
    const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff', 'pdf'];
    // 获取后缀
    const index = url.lastIndexOf('.');
    const ext = url.substr(index + 1);
    if (imgList.indexOf(ext.toLowerCase()) >= 0) {
      url += '?download=0';
    } else {
      url += '?download=1';
    }
  } else {
    url += '?download=1';
  }

  const a = document.createElement('a');
  a.setAttribute('href', url);
  if (fileName) a.setAttribute('download', fileName);

  a.setAttribute('style', 'display:none');
  a.setAttribute('target', target);
  document.body.appendChild(a);
  a.click();
  a.parentNode?.removeChild(a);
};
