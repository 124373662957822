import React, { lazy } from 'react';
import { useHistory } from 'react-router-dom';
import { getSession } from './common/util';

export const RouterUpdate: React.FC = (Props) => {
  const routerHistory = useHistory()
  let jumpKey: string;
  if (routerHistory.location.state) {
    jumpKey = (routerHistory.location.state as any).jumpKey;
  }else{
    if(routerHistory.location.pathname==='/'||routerHistory.location.pathname==='/home'){
      jumpKey = 'index';
    }
  }
  let historyInfo: { jumpKey: string, scrollTop: number }[] = getSession('historyInfo') || [];
  let pageInfo: { jumpKey: string, scrollTop: number } = { jumpKey: '', scrollTop: 0 }
  historyInfo.map((item, i) => {
    item.jumpKey === jumpKey ? pageInfo = item : ''
  })
  if (pageInfo && pageInfo.scrollTop != 0) {
    setTimeout(() => {
      window.scrollTo(0, pageInfo.scrollTop)
    }, 200)
  } else {
    window.scrollTo(0, 0)
  }
  return (
    <div>
      {Props.children}
    </div>
  )
};

const Home = lazy(() => import("./pages/home"));
const industrial = lazy(() => import("./pages/industrial"));
const groupProfile = lazy(() => import("./pages/groupProfile"));
const corporateCulture = lazy(() => import("./pages/corporateCulture"));
const investorProfile = lazy(() => import("./pages/investorProfile"));
const beijingHeadquarters = lazy(() => import("./pages/beijingHeadquarters"));
const investorNotice = lazy(() => import("./pages/investorNotice"));
const corporateGovernance = lazy(() => import("./pages/corporateGovernance"));
const investorStock = lazy(() => import("./pages/investorStock"));
const businessCooperation = lazy(() => import("./pages/businessCooperation"));
const partyBuilding = lazy(() => import("./pages/partyBuilding"));
const newsCenter = lazy(() => import("./pages/newsCenter"));
const newsDetail = lazy(() => import("./pages/newsDetail"));

const routes = [

  { name: "Home", path: "/", component: Home, exact: true },
  { name: "Home", path: "/home", component: Home, exact: true },
  { name: "Home", path: "/industrial", component: industrial, exact: true },
  { name: "Home", path: "/aboutBelg/groupProfile", component: groupProfile, exact: true },
  { name: "Home", path: "/aboutBelg/corporateCulture", component: corporateCulture, exact: true },
  { name: "Home", path: "/investor/investorProfile", component: investorProfile, exact: true },
  { name: "Home", path: "/contactUs/beijingHeadquarters", component: beijingHeadquarters, exact: true },
  { name: "Home", path: "/investor/investorNotice", component: investorNotice, exact: true },
  { name: "Home", path: "/investor/corporateGovernance", component: corporateGovernance, exact: true },
  { name: "Home", path: "/investor/investorStock", component: investorStock, exact: true },
  { name: "Home", path: "/contactUs/businessCooperation", component: businessCooperation, exact: true },
  { name: "Home", path: "/partyBuilding", component: partyBuilding, exact: true },
  { name: "Home", path: "/newsCenter", component: newsCenter, exact: true },
  { name: "Home", path: "/newsDetail", component: newsDetail, exact: true },

]
export default routes;